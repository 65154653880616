.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  color: inherit;
  gap: var(--space-s);
}

@media (--medium) {
  .root.isHero {
    flex-direction: row;
    align-items: center;
    gap: var(--space-l);

    & > * {
      flex: 1;
    }
  }
}

.coverWrapper {
  display: block;
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  margin: 0;
  overflow: hidden;
  border: 1px solid var(--color-border-subdued);
  border-radius: var(--border-radius-default);
  aspect-ratio: 16 / 9;
  clip-path: border-box;
  background-color: var(--color-background-light);
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.headlineLink {
  color: inherit;
  text-decoration: none;
  text-wrap: balance;

  &:hover {
    color: var(--color-accent-default);
  }
}

.excerpt {
  display: none;
}

@media (--medium) {
  .excerpt {
    display: block;
    margin-top: var(--space-s);
    text-wrap: balance;
  }
}

.details {
  width: 100%;
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.meta {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-s);
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.01em;
}

.metaList {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  gap: var(--space-s);
}

.metaTopics {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.metaListItem:not(:first-child)::before {
  content: '/';
  padding-right: var(--space-s);
  opacity: 0.5;
}

.metaDuration {
  margin-right: 0;
  margin-left: auto;
}

.metaLink {
  flex: 1;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--color-text-default);
  }
}

.metaReadingTime {
  margin-right: 0;
  margin-left: auto;
}

.nowrap {
  white-space: nowrap;
}

.date {
  display: flex;
  gap: 1ch;
}

.authors {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  margin-top: var(--space-s);
}

.author {
  display: flex;
  align-items: center;
  margin-top: var(--space-s);
  gap: var(--space-xs);
}

.authorImage {
  position: relative;
  width: 34px;
  aspect-ratio: 1/1;
  overflow: hidden;
  background-color: var(--color-background-light);
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}

.authorName {
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}
