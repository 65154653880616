.title {
  margin-bottom: var(--space-s);
}

.grid {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

@media (--small) {
  .grid {
    display: grid;
    gap: var(--space-2xl) var(--space-l);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (--medium) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .grid.hasHero {
    & > article:nth-child(1) {
      grid-area: 1 / 1 / 2 / 4;
    }

    & > article:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }

    & > article:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }

    & > article:nth-child(4) {
      grid-area: 2 / 3 / 3 / 4;
    }
  }
}
