.root {
  display: flex;
  align-items: flex-start;
  gap: var(--space-2xs);
}

@media (--medium) {
  .input {
    min-width: 400px;
  }
}
