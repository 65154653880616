/* SHARED BLOG STYLES */

.main {
  margin-top: var(--space-xl);
}

.main > * + * {
  margin-top: var(--space-xl);
}

.container {
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);
}

.pagination {
  margin-top: var(--space-2xl);
}

/* LANDING PAGE */

/* BLOG SINGLE PAGE */

.featImage {
  --feat-image-max-width: 800px;
}

.featImageWrapper {
  position: relative;
  width: auto;
  max-width: var(--feat-image-max-width);
  margin-top: calc(-1 * var(--blog-hero-overlap));
  overflow: hidden;
  border-radius: var(--border-radius-default);
  clip-path: content-box;
  aspect-ratio: 32/17; /* 1920x1080 */

  & > img {
    object-fit: cover;
  }
}

.featImageCaption {
  max-width: var(--feat-image-max-width);
}

.article,
.customExcerpt {
  max-width: var(--long-form-article-width);
}

.article {
  & figure {
    margin-inline: 0;

    & img {
      max-width: 100%;
      height: auto;
      border-radius: var(--border-radius-default);
      clip-path: content-box;
    }
  }

  & strong {
    font-weight: bold;
  }

  & h1,
  & h2 {
    margin-top: var(--space-2xl);
    font-family: var(--font-family-headline);
    font-size: var(--text-headline-m);
    letter-spacing: 0;
    line-height: 1.3;
  }

  & h3,
  & h4,
  & h5,
  & h6 {
    margin-top: var(--space-xl);
    font-family: var(--font-family-headline);
    font-size: var(--text-headline-s);
    letter-spacing: 0;
    line-height: 1.3;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    & strong,
    & b {
      font-weight: 400;
    }
  }

  & p,
  & li {
    font-size: var(--text-body-l);
    line-height: 1.8;
  }

  & a {
    border-bottom: 1px solid var(--color-text-default);
    color: var(--color-text-default);

    & u {
      text-decoration: none;
    }

    &:hover {
      border-bottom-color: var(--color-accent-default);
      color: var(--color-accent-default);
    }
  }

  & ol {
    padding-left: 2em;
  }

  & ol li {
    margin-bottom: var(--space-s);
  }

  & ul {
    padding-left: 0.2em;
    list-style: none;
  }

  & ul ul {
    margin-top: var(--space-s);
  }

  & ul li {
    position: relative;
    margin-bottom: var(--space-s);
    padding-left: 1.25em;
  }

  & ul li::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.5em;
    left: 0;
    width: 0.4em;
    height: 0.4em;
    transform: rotate(45deg);
    clip-path: polygon(
      10% 0%,
      90% 0%,
      100% 10%,
      100% 90%,
      90% 100%,
      10% 100%,
      0% 90%,
      0% 10%
    );
  }

  & > ul > li::after {
    background-color: var(--color-accent-default);
  }

  & > ul ul li::after {
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: var(--color-text-tertiary);
  }

  & blockquote {
    position: relative;
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
    margin-left: 0;
    padding-top: var(--blockquote-padding);
    padding-left: var(--blockquote-padding);
    font-family: var(--font-family-headline);
    font-size: var(--text-headline-l);
    letter-spacing: -0.0025em;
    line-height: 1.2;
    text-wrap: balance;

    &::before {
      content: '“';
      position: absolute;
      top: -0.1em;
      left: var(--blockquote-padding);
      opacity: 0.08;
      font-family: var(--font-family-mono);
      font-size: fluid(88px, 100px);
      line-height: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 24px;
      background: linear-gradient(
        0deg,
        #85dabb 0%,
        rgba(133 218 187 / 0%) 100%
      );
      clip-path: polygon(
        10px 0%,
        calc(100% - 10px) 0%,
        100% 10px,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        10px 100%,
        0% calc(100% - 10px),
        0% 10px
      );
    }
  }

  & table {
    border: 1px solid var(--color-border);

    & > thead th {
      padding: var(--space-s);
      border-right: 1px solid var(--color-border);
      border-bottom: 1px solid var(--color-border);
      font-weight: 700;
      text-align: left;
    }

    & > tbody tr {
      border-bottom: 1px solid var(--color-border);

      & td {
        padding: var(--space-s);
        border-right: 1px solid var(--color-border);
      }

      & td p {
        line-height: 1.2;
      }
    }
  }

  /* stylelint-disable */
  /* Targeting generated class from ghost */
  & :global(figure.kg-embed-card) {
    position: relative;
    width: 100%;
    padding-top: 56.5%;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  /* stylelint-enable */
}

.imageCaption,
.article figcaption {
  margin-top: var(--space-xs);
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
  letter-spacing: 0.0075em;
  line-height: 1.5;
}

.readMoreByAuthor {
  display: flex;
  flex-wrap: wrap;
  max-width: 70ch;
  margin-top: var(--space-2xl-3xl);
  gap: var(--space-l);
  border-top: 1px solid var(--color-border);
}

.authorName {
  font-size: var(--text-body-l);
}

.authorLink {
  display: flex;
  align-items: center;
  padding-top: var(--space-l);
  gap: var(--space-m);
  text-decoration: none;

  &:hover .authorName {
    color: var(--color-accent-default);
  }
}

.authorImage {
  position: relative;
  width: 54px;
  aspect-ratio: 1/1;
  overflow: hidden;
  background-color: var(--color-background-light);
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}

.authorImageLarge {
  width: 120px;
}

.authorDetails {
  display: flex;
  flex-direction: column;
  color: var(--color-text-default);
  font-family: var(--font-family-mono);
}

.readMoreBy {
  color: var(--color-text-tertiary);
  font-size: var(--text-body-s);
}

.metaAuthor {
  display: flex;
  align-items: center;
  margin-top: var(--space-xl);
  gap: var(--space-xs);
}

/* RESPONSIVE */
@media (--small) {
  .article {
    & > figure,
    & > blockquote {
      margin-right: -8ch;
    }
  }
}

@media (--medium) {
  .article {
    & > figure {
      margin-right: -25.5ch;
    }

    & > blockquote {
      margin-right: -8ch;
    }
  }
}
