.root {
  display: flex;
  align-items: center;
  gap: var(--space-2xs);
  border-top: 1px solid var(--color-border);
}

.meta {
  display: none;
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
}

.meta > strong {
  color: var(--color-text-default);
  font-weight: 400;
}

.nav {
  display: flex;
  align-items: center;
  gap: var(--space-2xs);
  margin: 0 0 0 auto;
}

.button {
  padding: var(--space-xs);
  border: 0;
  border-top: 1px solid transparent;
  background: transparent;
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
  cursor: pointer;
}

.button:hover,
.button:focus {
  color: var(--color-accent-hover);
}

.button[data-state='active'] {
  border-color: var(--color-accent-default);
  color: var(--color-text-default);
}

.button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.divider {
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

/* RESPONSIVE */
@media (--small) {
  .meta {
    display: block;
  }
}
